export const extractExcerpt = content => {
  if (!content) return null;

  const contentLength = content.length;

  const paragraphs = content.filter(c => c.type === 'paragraph');
  if (paragraphs.length === 0) return null;

  const first = paragraphs[0];
  const words = first.text.split(' ');

  const slicedWords = words.slice(0, 58);

  const longer = contentLength > 1 || slicedWords < words.length;

  let excerpt = slicedWords.join(' ');
  if (longer && excerpt[excerpt.length - 1] === '.') {
    excerpt = excerpt.slice(0, excerpt.length - 1);
  }

  return [excerpt, longer];
};
