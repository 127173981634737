export const formatDate = (dateString: string) => {
  const matches = dateString.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/);

  if (!matches) return null;

  const date = new Date(matches[0]);
  const formattedDate = date.toLocaleString('en-us', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return formattedDate;
};
